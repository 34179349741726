import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  viewBox: "0 0 184.25 184.25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      _cache[1] || (_cache[1] = _createElementVNode("clipPath", { id: "clippath" }, [
        _createElementVNode("path", {
          d: "M1.27.98h182.64v182.64H1.27z",
          class: "cls-1"
        })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("clipPath", { id: "clippath-1" }, [
        _createElementVNode("path", {
          d: "M1.27.98h182.64v182.64H1.27z",
          class: "cls-1"
        })
      ], -1)),
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-1{fill:none}.cls-3{fill:#fff}")
        ])),
        _: 1
      }))
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("g", { style: {"clip-path":"url(#clippath)"} }, [
      _createElementVNode("path", {
        d: "M92.59 183.61c50.43 0 91.32-40.88 91.32-91.32S143.02.98 92.59.98 1.27 41.86 1.27 92.3s40.89 91.32 91.32 91.32",
        style: {"fill":"#e40428"}
      })
    ], -1)),
    _cache[4] || (_cache[4] = _createElementVNode("path", {
      d: "M38.63 125.57h5.67l4.86 12.27h.04l4.62-12.27h5.64v16.57h-3.58l.04-13.19h-.04l-4.96 13.19h-3.58l-5.2-13.19h-.04l.04 13.19h-3.51zM61.84 138.52c0-2.85 1.43-3.6 5.5-3.6h2.94v-.62c0-1.32-.51-1.73-2.28-1.73s-2.28.36-2.28 1.49v.04h-3.41v-.21c0-2.85 1.43-3.88 5.62-3.88s5.77 1.13 5.77 4.07v8.05h-3.43v-1.53h-.04c-.47 1.09-1.02 1.7-3.9 1.7-3.26 0-4.49-1.15-4.49-3.79Zm5.73 1.15c1.83 0 2.75-.23 2.75-1.7v-.94h-2.66c-1.85 0-2.36.26-2.36 1.36 0 .94.43 1.28 2.28 1.28ZM76.69 141.06c-.47-.53-.66-1.21-.66-2.39v-.34h3.49v.13c0 .53.06.83.34 1.04.23.19.66.32 1.9.32 1.15 0 1.77-.08 2.09-.3.28-.19.34-.47.34-.85 0-.55-.13-.83-.51-.96-.26-.08-.53-.13-2.64-.28-1.92-.13-3.32-.28-4.03-.81-.66-.49-1-1.21-1-2.71s.4-2.41 1.15-2.96c.96-.72 2.26-.94 4.43-.94 2.77 0 4.11.38 4.9 1.26.53.58.68 1.36.68 2.41v.21h-3.43v-.02c0-.49-.04-.79-.32-1-.32-.26-.89-.34-1.83-.34-1.02 0-1.58.06-1.85.28-.23.17-.32.38-.32.83 0 .55.17.77.45.87s.75.15 2.15.26c2.6.19 3.86.32 4.6.83.81.55 1.07 1.51 1.07 2.79 0 1.53-.36 2.41-1.19 3.02-.89.66-2.34.92-4.73.92-3.05 0-4.3-.4-5.07-1.28ZM94.19 137.12h-.81v5.03h-3.49v-16.57h3.49v9.03h.7l3.28-4.39h4.09l-4.28 5.5 4.67 6.43h-4.17l-3.47-5.03ZM103.07 137.73v-7.52h3.49v6.77c0 1.98.53 2.37 2.26 2.37 1.96 0 2.28-.41 2.28-2.58v-6.56h3.49v11.93h-3.47v-1.7h-.04c-.47 1.26-1.47 1.87-3.68 1.87-3.13 0-4.32-1.32-4.32-4.58ZM119.69 128.74h-5.54v-3.17h14.83v3.17h-5.58v13.4h-3.71zM130.78 125.57h3.49v3.11h-3.49zm0 4.64h3.49v11.93h-3.49zM137 125.57h3.49v16.57H137zM143.22 125.57h3.49v3.11h-3.49zm0 4.64h3.49v11.93h-3.49z",
      class: "cls-3"
    }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("path", {
      d: "M127.63 119.09V88.94l-9.94 21.34a8.77 8.77 0 0 1-7.95 5.07h-34.6c-3.41 0-6.51-1.98-7.95-5.07l-9.94-21.34v30.15H35.99v-77.1h22.33L83 94.09h18.89l24.68-52.1h22.33v77.1h-21.26Z",
      class: "cls-3",
      style: {"clip-path":"url(#clippath-1)"}
    }, null, -1))
  ]))
}
export default { render: render }